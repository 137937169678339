

















import { Prop, Component, Vue } from 'vue-property-decorator'

@Component
export default class HttpError extends Vue {
    @Prop(Number) statusCode!: number;
    @Prop(String) statusMessage?: number;
}
